var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: { width: "1480px", title: _vm.title, visible: _vm.visible },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c("div", { staticClass: "actions flex jc-end mb-4" }),
      _c("a-table", {
        style: _vm.backCss,
        attrs: {
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            columnWidth: 5,
          },
          columns: _vm.columns,
          rowKey: "id",
          customRow: _vm.clickRow,
          scroll: { x: "1380px", y: "500px" },
          "data-source": _vm.dataSource,
        },
        scopedSlots: _vm._u([
          {
            key: "taskName",
            fn: function (text, record) {
              return [
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        record.productName +
                          "-" +
                          record.chapterOrder +
                          "-" +
                          record.taskName
                      ) +
                      "\n      "
                  ),
                ]),
              ]
            },
          },
          {
            key: "user",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "30px",
                        height: "30px",
                        "border-radius": "50%",
                        "margin-right": "10px",
                      },
                      attrs: { src: record.avatar, alt: "" },
                    }),
                    _vm._v("\n        " + _vm._s(record.userName) + "\n      "),
                  ]
                ),
              ]
            },
          },
          {
            key: "completeStatus",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "up-msg-status",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c("actions-tasks", {
                      attrs: {
                        propsParams: record,
                        isMyTask: true,
                        isMyTaskList: true,
                        isCalendar: true,
                        loadData: _vm.getTableData,
                      },
                      on: { openTaskModel: _vm.openTaskModel },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "finishFileCount",
            fn: function (text, record) {
              return [
                _c("div", [
                  _c("span", { staticClass: "img-num-title" }, [
                    _vm._v("完成稿："),
                  ]),
                  _c("span", [_vm._v(_vm._s(record.finishFileCount || 0))]),
                ]),
                record.departName.indexOf("日语部") > -1
                  ? _c("div", [
                      _c("span", { staticClass: "img-num-title" }, [
                        _vm._v("切片："),
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(record.sectionFileCount || 0)),
                      ]),
                    ])
                  : _vm._e(),
                record.departName.indexOf("日语部") > -1
                  ? _c("div", [
                      _c("span", { staticClass: "img-num-title" }, [
                        _vm._v("tmb："),
                      ]),
                      _c("span", [_vm._v(_vm._s(record.tmbFileCount || 0))]),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "jd",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  [
                    _c("a-progress", {
                      attrs: { percent: record.bottomProgress },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "textStatus",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  { staticClass: "text-status" },
                  [
                    _c("a-icon", { attrs: { type: "check-circle" } }),
                    _c("span", { staticStyle: { "margin-left": "3px" } }, [
                      _vm._v("读取完成"),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c(
                      "a-upload",
                      {
                        attrs: {
                          name: "file",
                          showUploadList: false,
                          accept: ".jpg,.psd,.png,.zip",
                          multiple: true,
                          disabled: record.loading,
                          "before-upload": (file, fileList) =>
                            _vm.beforeUpload(file, fileList, record, "1"),
                          "custom-request": _vm.handleRequest,
                        },
                      },
                      [
                        _c(
                          "a-spin",
                          {
                            staticStyle: { "margin-left": "15px" },
                            attrs: { spinning: record.loading },
                          },
                          [
                            _c("a-icon", {
                              staticStyle: { "font-size": "24px" },
                              attrs: {
                                slot: "indicator",
                                type: "loading",
                                spin: "",
                              },
                              slot: "indicator",
                            }),
                            _c("a", [_vm._v("上传完成稿")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    record.departName.indexOf("日语部") > -1
                      ? _c(
                          "a-upload",
                          {
                            attrs: {
                              name: "file",
                              showUploadList: false,
                              accept: ".jpg,.psd,.png,.zip",
                              multiple: true,
                              disabled: record.loading,
                              "before-upload": (file, fileList) =>
                                _vm.beforeUpload(file, fileList, record, "2"),
                              "custom-request": _vm.handleRequest,
                            },
                          },
                          [
                            _c(
                              "a-spin",
                              {
                                staticStyle: { "margin-left": "15px" },
                                attrs: { spinning: record.loading },
                              },
                              [
                                _c("a-icon", {
                                  staticStyle: { "font-size": "24px" },
                                  attrs: {
                                    slot: "indicator",
                                    type: "loading",
                                    spin: "",
                                  },
                                  slot: "indicator",
                                }),
                                _c("a", [_vm._v("上传切片")]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    record.departName.indexOf("日语部") > -1
                      ? _c(
                          "a-upload",
                          {
                            attrs: {
                              name: "file",
                              showUploadList: false,
                              accept: ".jpg,.psd,.png,.zip",
                              disabled: record.loading,
                              multiple: true,
                              "before-upload": (file, fileList) =>
                                _vm.beforeUpload(file, fileList, record, "3"),
                              "custom-request": _vm.handleRequest,
                            },
                          },
                          [
                            _c(
                              "a-spin",
                              {
                                staticStyle: { "margin-left": "15px" },
                                attrs: { spinning: record.loading },
                              },
                              [
                                _c("a-icon", {
                                  staticStyle: { "font-size": "24px" },
                                  attrs: {
                                    slot: "indicator",
                                    type: "loading",
                                    spin: "",
                                  },
                                  slot: "indicator",
                                }),
                                _c("a", [_vm._v("上传tmb")]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
            _vm._v("\n      关闭\n    "),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }